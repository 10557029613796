import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import ESET_Partner_logo from '../../../assets/images/features/feature13/ESET_Partner_logo.png';


const Images = () => {
    return (
        <div>
            <div className="partner-section" id="partner-section">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                        <h1 className="title font-bold text-white">Our Partner</h1>
                        <h6 className="subtitle text-white">We collaborate with trusted partner to deliver exceptional IT solutions. Together, we ensure your technology needs are met with precision and expertise.</h6>
                        </Col>
                    </Row>
                </Container>
            <Container>
            <Row className="justify-content-center">
                <Col xs="6" sm="4" md="3" className="text-center m-b-30">
                    <img src={ESET_Partner_logo} alt="img" className="img-responsive-partner img-rounded" width="200" />
                </Col>
            </Row>
            </Container>
        </div>
        </div>
    );
}

export default Images;

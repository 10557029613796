import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import ComprehensiveNetwork2 from '../../../assets/images/services/Network-Security-Setup/Comprehensive-Network-Security-Solutions2.png';
import EmployeeSecurity from '../../../assets/images/services/Network-Security-Setup/Employee-Security-Training.png';
import ExpertNetwork from '../../../assets/images/services/Network-Security-Setup/Expert-Network-Security.png';
import FirewallInstallation from '../../../assets/images/services/Network-Security-Setup/Firewall-Installation-&-Configuration.jpeg';
import NetworkMonitoring from '../../../assets/images/services/Network-Security-Setup/Network-Monitoring-Services.png';

const NetworkSecuritySetup = () => {
    const handleConsultationClick = () => {
        // Define your consultation link or logic here
        window.location.href = "/consultation"; // Example redirect
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Network Security Setup | Maestros Technical Services</title>
                <meta name="description" content="Ensure your business is secure with our comprehensive network security setup services. Expert solutions for UK businesses. Firewall installation, monitoring, and more." />
                <meta name="keywords" content="network security, IT security, cybersecurity, firewall installation, employee security training, network monitoring, UK business IT solutions" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Network Security Setup | Maestros Technical Services" />
                <meta property="og:description" content="Comprehensive network security services including firewall installation, network monitoring, and employee training. Protect your business from cyber threats today!" />
                <meta property="og:image" content={ExpertNetwork} />
                <meta property="og:url" content="https://www.mtechsecurity.com/network-security-setup" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Network Security Setup | Maestros Technical Services" />
                <meta name="twitter:description" content="Comprehensive network security services to secure your business with expert solutions, including firewall setup and network monitoring." />
                <meta name="twitter:image" content={ExpertNetwork} />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="network-security-cover" id="network-security">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <h1 className="title text-white font-bold">Network Security Setup & Management</h1>
                                    <h5 className="subtitle font-light text-white">
                                        At Maestros, we offer comprehensive network security solutions, ensuring your business infrastructure remains protected from evolving cyber threats. Our focus is on delivering tailored security services that meet the unique needs of your organisation.
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="spacer">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h2 className="title">Our Network Security Services</h2>
                                    <h6 className="subtitle">Count on our expertise for effective network security solutions that protect your business.</h6>
                                </Col>
                            </Row>
                            <Row className="m-t-40">
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={FirewallInstallation} alt="Firewall Installation and Configuration for Network Security" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Firewall Installation & Configuration</h5>
                                            <p className="m-b-0 font-14">
                                                Our experts will install and configure firewalls tailored to your network's architecture, providing robust protection against unauthorised access and cyber attacks. We ensure your firewall is optimised for peak performance.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={NetworkMonitoring} alt="Continuous Network Monitoring Services" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Network Monitoring Services</h5>
                                            <p className="m-b-0 font-14">
                                                We offer continuous network monitoring to detect vulnerabilities and respond to threats in real-time, ensuring your network remains secure and operational. Our proactive monitoring helps prevent potential issues before they escalate.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={EmployeeSecurity} alt="Employee Security Training for Cyber Awareness" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Employee Security Training</h5>
                                            <p className="m-b-0 font-14">
                                                We provide training sessions for your staff to enhance their awareness of security best practices, minimising risks associated with human error. Empower your employees to be the first line of defence against cyber threats.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="bg-light">
                        <section>
                            <div id="banner1" className="banner spacer">
                                <Container>
                                    <Row>
                                        <Col lg="5" md="7" className="align-self-center">
                                            <h2 className="title font-bold">Expert Network Security Solutions at Maestros</h2>
                                            <p className="m-t-40 m-b-30">
                                                Our network security services are designed to protect your digital infrastructure from potential threats. From setup to ongoing management, our skilled team ensures your network remains secure and resilient against evolving cyber attacks.
                                            </p>
                                        </Col>
                                        <Col lg="6" md="5" className="align-self-center ms-auto">
                                            <img src={ExpertNetwork} alt="Comprehensive Network Security Solutions" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                    </div>
                    <div className="spacer">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h2 className="title">Comprehensive Network Security: Get Assistance Anytime, Anywhere</h2>
                                    <h6 className="subtitle">At Maestros, we provide expert network security solutions tailored to your needs.</h6>
                                    <p>
                                        Our network security services cover everything from firewall setup to employee training, ensuring your business is protected against cyber threats. With our dedicated team by your side, you can focus on your core operations while we safeguard your network. Let us be your trusted partner in network security.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container className="feature30">
                            <Row>
                                <Col lg="10">
                                    <img src={ComprehensiveNetwork2} className="rounded img-responsive" alt="Comprehensive Network Security Solutions for Businesses" />
                                </Col>
                                <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                    <Card className="card-shadow">
                                        <CardBody>
                                            <div className="p-20">
                                                <span className="label label-info label-rounded">Your Network Security Partner</span>
                                                <h3 className="title">Comprehensive Network Security Solutions</h3>
                                                <p>
                                                    At Maestros, we deliver comprehensive network security services using the latest technologies. Our team is committed to ensuring your business's data and systems are secure and compliant with industry standards.
                                                </p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Need network security assistance? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our Network Security Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we provide comprehensive network security services, including firewall installation, monitoring, and training.
                                        <br />
                                        Enjoy peace of mind knowing your network is secure. Ready to protect your IT environment? Sign up for a free consultation today!
                                    </h6>
                                    <Button color="btn btn-success-gradiant" className="mt-3" onClick={handleConsultationClick}>
                                        Start Your Free Consultation
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default NetworkSecuritySetup;

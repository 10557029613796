import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from 'reactstrap';

// Core components
import ContactForm from "../sections/contactcomponent.jsx";

import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";
import MTSPL from '../../../assets/images/info/About-us.png';

// SEO Elements
export const seo = {
    title: "Comprehensive IT Support Services in the UK | Maestros Technical Services",
    description: "Reliable IT solutions from cybersecurity to VoIP services, ensuring peace of mind for UK businesses and individuals. Let us handle your tech so you can focus on what truly matters.",
};

const Aboutus = () => {
    return (
        <div id="main-wrapper">
            <Helmet>
                <title>{seo.title}</title>
                <meta name="description" content={seo.description} />
            </Helmet>
            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="about-us">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="6" className="align-self-center text-center">
                                    <h1 className="title text-white font-bold">Welcome to Maestros Technical Services Private Limited</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="bg-light">
                        <section>
                            <div id="banner2" className="banner spacer">
                                <Container>
                                    <Row>
                                        <Col lg="5" md="7" className="align-self-center">
                                            <h2 className="title font-bold">About Us</h2>
                                            <p className="m-t-40 m-b-30">
                                                Founded on November 11, 2013, Maestros Technical Services Private Limited specializes in delivering tailored IT solutions that meet the diverse needs of our clients. Our services range from website maintenance to multimedia presentation creation, ensuring businesses and individuals can thrive in today's digital landscape.
                                            </p>
                                            <p>
                                                Our team is led by experienced directors Braj Bihari Kumar and Swatantra Kumar Bharti, who are passionate about technology and dedicated to providing high-quality support. We believe in the power of technology to transform operations, enhance productivity, and foster growth.
                                            </p>
                                            <p>
                                                At Maestros Technical Services, we prioritize reliability and client satisfaction. We work closely with each client to understand their unique needs and deliver solutions that exceed expectations. Whether you're looking for cybersecurity services, VoIP solutions, or comprehensive tech support, we are here to help you navigate the complexities of technology.
                                            </p>
                                        </Col>
                                        <Col lg="6" md="5" className="align-self-center ms-auto">
                                            <img src={MTSPL} alt="IT Support and Cybersecurity Services in the UK" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                    </div>
                    <ContactForm />
                    <div className="about-us-commitment" id="aboutuscommitment">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h2 className="title text font-weight-bold">Our Commitment to Excellence</h2>
                                            <p>
                                                At Maestros Technical Services, we are dedicated to delivering exceptional IT solutions that empower our clients to succeed. Our commitment to quality and innovation drives us to continuously improve our services and exceed expectations.
                                            </p>
                                            <p className="mt-3">
                                                We prioritize transparency, collaboration, and customer satisfaction in every project. By understanding your unique needs, we create tailored solutions that not only address your current challenges but also anticipate future growth. Trust us to be your reliable partner in navigating the ever-evolving tech landscape.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Aboutus;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Row,
    Col,
    Container,
} from "reactstrap";

// core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

const PrivacyPolicy = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Privacy Policy | Maestros Technical Services</title>
                <meta name="description" content="Learn about our privacy policy and how we protect your information at Maestros Technical Services." />
                <meta name="keywords" content="privacy policy, data protection, UK" />
            </Helmet>
            <div className="page-wrapper">
            <div className="container-fluid">
                    <div className="policies-cover" id="policies">
                        <Container className="py-5 mt-5">
                            <Row>
                            <Col md="12">
                                    <div>
                                        <div>
                                        <h1 className="title text font-bold">Privacy Policy</h1>
                                        <h5 className="subtitle font-light text">
                                        <strong>Effective Date:</strong> 13-October-2024                                        </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className='policies-content'>
                <p>At Maestros Technical Services Limited, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information.</p>

                <h4>1. Information We Collect</h4>
                <p>We may collect personal information when you interact with our website, including your name, email address, phone number, and any other information you provide.</p>

                <h4>2. How We Use Your Information</h4>
                <p>We use your information to provide and improve our services, communicate with you, and send you updates. We may also use your information for marketing purposes with your consent.</p>

                <h4>3. Data Security</h4>
                <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>

                <h4>4. Cookies</h4>
                <p>Our website may use cookies to enhance user experience. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.</p>

                <h4>5. Your Rights</h4>
                <p>You have the right to request access to your personal information, to request corrections to it, and to request its deletion under certain circumstances.</p>

                <h4>6. Changes to This Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website, and the effective date will be updated accordingly.</p>

                <h4>7. Contact Us</h4>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@mtechsecurity.com">support@mtechsecurity.com</a>.</p>
                 </div>
                <Footer />
            </div>
        </div>
    );
}

export default PrivacyPolicy;

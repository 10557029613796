import React, { useState } from "react";
import { Row, Col, Container, Form, FormGroup, Input, Button, Alert } from "reactstrap";
import axios from "axios";

const ContactComponentForServices = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [alertColor, setAlertColor] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');
    setAlertColor('');

    if (!validateEmail(formData.email)) {
      setResponseMessage('Please enter a valid email address.');
      setAlertColor('danger');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post('https://winclean.mtechsecurity.com/email/send_email.php', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status === 'success') {
        setResponseMessage(response.data.message);
        setAlertColor('success');
        setFormData({ name: '', email: '', mobile: '', subject: '', message: '' }); // Clear form data
      } else {
        setResponseMessage(response.data.message || 'Unexpected error occurred.');
        setAlertColor('danger');
      }
    } catch (error) {
      setResponseMessage('Error sending email: ' + (error.response?.data?.message || error.message));
      setAlertColor('danger');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="spacer bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h1 className="title font-bold">Share Your Feature Ideas</h1>
              <h5>
                Your ideas matter! Share your thoughts and feature requests, and our dedicated team will work tirelessly to integrate them into our services. Together, we can create solutions that elevate your experience and security.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact1">
        <Container>
          <Row>
            <div className="spacer">
              <Row className="m-0">
                <Col lg="8">
                  <div className="contact-box p-r-40">
                    {responseMessage && (
                      <Alert color={alertColor} toggle={() => setResponseMessage('')}>
                        {responseMessage}
                      </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input
                              type="email"
                              name="email"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input
                              type="text"
                              name="mobile"
                              placeholder="Mobile Number"
                              value={formData.mobile}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="m-t-15">
                            <Input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              value={formData.subject}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className="m-t-15">
                            <Input
                              type="textarea"
                              name="message"
                              placeholder="Your Message"
                              value={formData.message}
                              onChange={handleChange}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                        <Button
                            type="submit"
                            className="btn btn-success waves-effect waves-light m-r-10"
                            disabled={isSubmitting}
                          >
                            <span>
                              {isSubmitting ? 'Sending Email...' : 'SUBMIT'} <i className="ti-arrow-right"></i>
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="detail-box p-40 bg-info">
                    <h2 className="text-white">Maestros Headquarters</h2>
                    <p className="text-white m-t-30 op-8">
                      +44 7427 575431
                      <br /> support@mtechsecurity.com
                    </p>
                    <p className="text-white op-8">
                      Suite 5043, Unit 3A
                      <br /> 34-35 Hatton Garden
                      <br /> Holborn, London EC1N 8DX
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactComponentForServices;

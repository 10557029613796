import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import AntivirusServicesinstallation from '../../../assets/images/services/Antivirus-Services/AntivirusServicesinstallation.png';
import AntivirusServicessupport from '../../../assets/images/services/Antivirus-Services/AntivirusServicessupport.png';
import AntivirusServicesongoingprotection from '../../../assets/images/services/Antivirus-Services/AntivirusServicesongoingprotection.png';
import AntivirusServicesexport from '../../../assets/images/services/Antivirus-Services/AntivirusServicesexport.png';
import AntivirusServicesdedicated from '../../../assets/images/services/Antivirus-Services/AntivirusServicesdedicated.png';

const AntivirusServices = () => {
    const handleConsultationClick = () => {
        // Define your consultation link or logic here
        window.location.href = "/consultation"; // Example redirect
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Antivirus Software Installation | Maestros Technical Services</title>
                <meta name="description" content="Protect your systems with our expert antivirus software installation services. Comprehensive security solutions in the UK." />
                <meta name="keywords" content="antivirus services, software installation, IT security, UK, virus removal, cybersecurity" />
                <meta property="og:title" content="Antivirus Software Installation | Maestros Technical Services" />
                <meta property="og:description" content="Protect your systems with our expert antivirus software installation services. Comprehensive security solutions in the UK." />
                <meta property="og:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
                <meta property="og:url" content="https://mtechsecurity.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Antivirus Software Installation | Maestros Technical Services" />
                <meta name="twitter:description" content="Protect your systems with our expert antivirus software installation services. Comprehensive security solutions in the UK." />
                <meta name="twitter:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="antivirus-cover" id="antivirus">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <h1 className="title text-white font-bold">Antivirus Software Installation & Support Services</h1>
                                    <h5 className="subtitle font-light text-white">
                                        At Maestros, we provide comprehensive antivirus solutions, including installation and expert support to ensure your system remains secure against digital threats. Our commitment is to safeguard your data and provide you with peace of mind.
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="spacer">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h2 className="title">Our Antivirus Services</h2>
                                    <h6 className="subtitle">Depend on our expertise for effective antivirus solutions that keep your devices secure.</h6>
                                </Col>
                            </Row>
                            <Row className="m-t-40">
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={AntivirusServicesinstallation} alt="Antivirus Software Installation Service" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Antivirus Software Installation</h5>
                                            <p className="m-b-0 font-14">
                                                Our team ensures seamless installation of antivirus software tailored to your system's needs. We configure settings for optimal performance, allowing you to have peace of mind knowing your devices are secure from threats like malware and ransomware.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={AntivirusServicessupport} alt="Antivirus Software Support Services" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Antivirus Software Support Services</h5>
                                            <p className="m-b-0 font-14">
                                                Our dedicated support team is available to troubleshoot any antivirus-related issues you may encounter. From virus removal to software updates, we ensure your protection remains effective and up to date, giving you confidence in your system's security.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <Card className="card-shadow">
                                        <a href="#" className="img-ho">
                                            <img className="card-img-top" src={AntivirusServicesongoingprotection} alt="Ongoing Antivirus Protection Services" />
                                        </a>
                                        <CardBody>
                                            <h5 className="font-medium m-b-0">Ongoing Protection Services</h5>
                                            <p className="m-b-0 font-14">
                                                We provide ongoing monitoring and updates to ensure your antivirus software remains effective against new threats. Our proactive approach helps you stay protected, keeping your data safe from evolving cyber threats.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="bg-light">
                        <section>
                            <div id="banner1" className="banner spacer">
                                <Container>
                                    <Row>
                                        <Col lg="5" md="7" className="align-self-center">
                                            <h2 className="title font-bold">Expert Antivirus Solutions at Maestros</h2>
                                            <p className="m-t-40 m-b-30">
                                                Our antivirus services are designed to protect your systems from the ever-evolving landscape of digital threats. From the initial installation to ongoing support, our skilled team is here to provide solutions tailored to your specific needs, all in compliance with UK regulations like GDPR.
                                            </p>
                                        </Col>
                                        <Col lg="6" md="5" className="align-self-center ms-auto">
                                            <img src={AntivirusServicesexport} alt="Expert Antivirus Solutions" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                    </div>
                    <div className="spacer">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h2 className="title">Comprehensive Antivirus Protection: Get Help Anytime, Anywhere</h2>
                                    <h6 className="subtitle">At Maestros, we provide expert antivirus solutions tailored to your needs.</h6>
                                    <p>
                                        Our antivirus services cover everything from installation to ongoing support, ensuring your devices are safeguarded against malware and other threats. With our team at your side, you can focus on what matters most while we handle your cybersecurity needs with utmost care.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container className="feature30">
                            <Row>
                                <Col lg="5">
                                    <img src={AntivirusServicesdedicated} className="rounded img-responsive" alt="Dedicated Antivirus Solutions" />
                                </Col>
                                <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                    <Card className="card-shadow">
                                        <CardBody>
                                            <div className="p-20">
                                                <span className="label label-info label-rounded">Your Antivirus Partner</span>
                                                <h3 className="title">Comprehensive Antivirus Solutions</h3>
                                                <p>
                                                    At Maestros, we offer comprehensive antivirus services to keep your systems secure. Our team employs the latest technology to deliver effective solutions, ensuring your data remains safe and your devices perform at their best.
                                                </p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Need antivirus assistance? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our Antivirus Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we provide comprehensive antivirus services, including software installation, expert support, and ongoing protection.
                                        <br />
                                        Enjoy peace of mind knowing your devices are secure. Ready to safeguard your IT environment? Sign up for a free consultation today!
                                    </h6>
                                    <Button color="btn btn-success-gradiant" className="mt-3" onClick={handleConsultationClick}>
                                        Start Your Free Consultation
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default AntivirusServices;

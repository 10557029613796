import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import TechSupport from '../../../assets/images/services/Remote-Support/Tech-Support.jpg';
import CybersecuritySolutions from '../../../assets/images/services/Remote-Support/cybersecurity-solutions.png';
import NetworkSecuritySetup from '../../../assets/images/services/Remote-Support/network-security-setup.png';
import ExpertRemoteSupport from '../../../assets/images/services/Remote-Support/Expert-Remote-Support.svg';
import ComprehensiveRemoteSupport from '../../../assets/images/services/Remote-Support/Comperhensive-Remote-Support.png';

const RemoteSupport = () => {
    const handleTrialClick = () => {
        // Define your free trial link or logic here
        window.location.href = "/free-trial"; // Example redirect
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Remote IT Support | Maestros Technical Services</title>
                <meta name="description" content="Get instant help with our remote IT support services. Efficient solutions for tech issues from anywhere in the UK." />
                <meta name="keywords" content="remote support, IT support, tech help, cybersecurity, network security, UK, tech assistance, IT troubleshooting" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Remote IT Support Services | Maestros Technical Services" />
                <meta property="og:description" content="Receive expert remote IT support for tech issues, cybersecurity, and network security setup. We provide prompt, efficient solutions to keep your business and systems secure." />
                <meta property="og:image" content={ExpertRemoteSupport} />
                <meta property="og:url" content="https://www.mtechsecurity.com/remote-support" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Remote IT Support Services | Maestros Technical Services" />
                <meta name="twitter:description" content="Reliable remote IT support for tech help, cybersecurity, and network security. We help you resolve issues quickly and securely, wherever you are." />
                <meta name="twitter:image" content={ExpertRemoteSupport} />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="remote-support-cover" id="remote-support">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <div>
                                        <h1 className="title text-white font-bold">Remote Support Services</h1>
                                        <h5 className="subtitle font-light text-white">
                                            At Maestros, we provide comprehensive remote support services tailored for individuals and businesses. From tech support to cybersecurity and network security setup, our expert team is ready to assist you anytime, ensuring your systems run smoothly and securely. Trust us to keep your technology in top shape.
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Our Remote Support Services</h2>
                                        <h6 className="subtitle">Depend on our expertise for effective remote assistance.</h6>
                                    </Col>
                                </Row>
                                <Row className="m-t-40">
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={TechSupport} alt="Remote Tech Support for Immediate Assistance" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Tech Support</h5>
                                                <p className="m-b-0 font-14">
                                                    Our remote tech support ensures immediate assistance for any technical issues. From software troubleshooting to system optimisation, our team efficiently handles a wide range of problems to keep your technology functioning at its best.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={CybersecuritySolutions} alt="Comprehensive Cybersecurity Solutions for Business Protection" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Cybersecurity Solutions</h5>
                                                <p className="m-b-0 font-14">
                                                    Protect your business with our comprehensive cybersecurity services. We offer remote monitoring and incident response, ensuring your systems are safeguarded against threats. Our proactive measures keep your data secure.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={NetworkSecuritySetup} alt="Network Security Setup for Enhanced Protection" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Network Security Setup</h5>
                                                <p className="m-b-0 font-14">
                                                    Our experts provide remote setup and configuration of network security solutions. We help establish firewalls, VPNs, and secure access protocols to ensure that your network is protected from unauthorised access and cyber threats.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="bg-light">
                            <section>
                                <div id="banner1" className="banner spacer">
                                    <Container>
                                        <Row>
                                            <Col lg="5" md="7" className="align-self-center">
                                                <h2 className="title font-bold">Expert Remote Support at Maestros</h2>
                                                <p className="m-t-40 m-b-30">
                                                    Our remote support services are designed to provide immediate assistance whenever you need it. From tech support to comprehensive cybersecurity solutions, we ensure that your technology is optimised and secure. Our skilled team conducts thorough assessments and employs state-of-the-art tools to resolve your issues quickly, allowing you to focus on your core business activities.
                                                </p>
                                            </Col>
                                            <Col lg="6" md="5" className="align-self-center ms-auto">
                                                <img src={ExpertRemoteSupport} alt="Expert Remote Support Services" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </section>
                        </div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Streamlined Remote Support: Get Help Anytime, Anywhere</h2>
                                        <h6 className="subtitle">At Maestros, we provide expert assistance tailored to your needs.</h6>
                                        <p>
                                            Our remote support services are designed to address your technology challenges promptly. Whether you require tech support, cybersecurity measures, or network security setup, our experienced team is just a click away, ready to help you maintain smooth operations.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="spacer bg-light">
                            <Container className="feature30">
                                <Row>
                                    <Col lg="10">
                                        <img src={ComprehensiveRemoteSupport} className="rounded img-responsive" alt="Comprehensive Remote Support Solutions" />
                                    </Col>
                                    <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                        <Card className="card-shadow">
                                            <CardBody>
                                                <div className="p-20">
                                                    <span className="label label-info label-rounded">Your Remote Support Partner</span>
                                                    <h3 className="title">Expert Remote Support Solutions</h3>
                                                    <p>
                                                        At Maestros, we provide comprehensive remote support services to address all your technology needs. Our team utilises the latest tools and techniques to deliver effective solutions, ensuring your systems are secure and optimised for peak performance.
                                                    </p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">                        
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Ready for reliable support? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our Remote Support Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we provide expert remote support services, including tech support, cybersecurity assistance, and network security setup.
                                        <br />
                                        Enjoy seamless assistance from the comfort of your home or office. Ready to enhance your IT support? Sign up for a free trial of our services today!
                                    </h6>
                                    <Button color="primary" className="mt-3" onClick={handleTrialClick}>
                                        Start Your Free Trial
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default RemoteSupport;

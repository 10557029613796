import React from "react";
import "./assets/scss/style.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"; 
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component

// Import pages
import Homepage from "./views/Components/homepage.jsx";
import DataRecovery from "./views/Components/services/datarecovery.jsx";
import VirusMalwareRemoval from "./views/Components/services/virusmalwareremoval.jsx";
import PcMaintenance from "./views/Components/services/pcmaintenance.jsx";
import RemoteSupport from "./views/Components/services/remotesupport.jsx";
import Servicerequest from "./views/Components/services/Servicerequest.jsx";
import Downloads from "./views/Components/services/downloads.jsx";
import AntivirusServices from "./views/Components/services/antivirusServices.jsx";
import NetworkSecuritySetup from "./views/Components/services/networksecuritysetup.jsx";
import TermsOfUse from "./views/Components/policies/term-of-use.jsx";
import PrivacyPolicy from "./views/Components/policies/privacy-policy.jsx";
import LegalDisclaimer from "./views/Components/policies/legal-disclaimer.jsx";
import Aboutus from "./views/Components/info/about.jsx";
import Contactus from "./views/Components/info/contact.jsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <Router>
      <ScrollToTop /> {/* Add the ScrollToTop component here */}
      <Routes>
        {/* Main Routes */}
        <Route path="/" element={<Homepage />} />
        
        {/* Service Routes */}
        <Route path="/Servicerequest" element={<Servicerequest />} />
        <Route path="/services/Servicerequest" element={<Navigate to="/Servicerequest" />} />

        <Route path="/Downloads" element={<Downloads />} />
        <Route path="/services/Downloads" element={<Navigate to="/Downloads" />} />

        <Route path="/datarecovery" element={<DataRecovery />} />
        <Route path="/services/datarecovery" element={<Navigate to="/datarecovery" />} />

        <Route path="/virusMalwareRemoval" element={<VirusMalwareRemoval />} />
        <Route path="/services/virusMalwareRemoval" element={<Navigate to="/virusMalwareRemoval" />} />

        <Route path="/pcmaintenance" element={<PcMaintenance />} />
        <Route path="/services/pcmaintenance" element={<Navigate to="/pcmaintenance" />} />

        <Route path="/remotesupport" element={<RemoteSupport />} />
        <Route path="/services/remotesupport" element={<Navigate to="/remotesupport" />} />

        <Route path="/antivirusServices" element={<AntivirusServices />} />
        <Route path="/services/antivirusServices" element={<Navigate to="/antivirusServices" />} />

        <Route path="/networksecuritysetup" element={<NetworkSecuritySetup />} />
        <Route path="/services/networksecuritysetup" element={<Navigate to="/networksecuritysetup" />} />

        {/* Policies Routes */}
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/policies/terms-of-use" element={<Navigate to="/terms-of-use" />} />

        <Route path="/legal-disclaimer" element={<LegalDisclaimer />} />
        <Route path="/policies/legal-disclaimer" element={<Navigate to="/legal-disclaimer" />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/policies/privacy-policy" element={<Navigate to="/privacy-policy" />} />

        {/* Info Routes */}
        <Route path="/about" element={<Aboutus />} />
        <Route path="/info/about" element={<Navigate to="/about" />} />

        <Route path="/contact" element={<Contactus />} />
        <Route path="/info/contact" element={<Navigate to="/contact" />} />
      </Routes>
    </Router>
  </HelmetProvider>
);

reportWebVitals();

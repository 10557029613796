import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Row,
    Col,
    Container,
} from "reactstrap";

// core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

const LegalDisclaimer = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Legal Disclaimer | Maestros Technical Services</title>
                <meta name="description" content="Read our legal disclaimer to understand the limitations and liabilities of Maestros Technical Services." />
                <meta name="keywords" content="legal disclaimer, terms and conditions, UK" />
            </Helmet>
            <div className="page-wrapper">
            <div className="container-fluid">
                    <div className="policies-cover" id="policies">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="12">
                                    <div>
                                        <div>
                                        <h1 className="title text font-bold">Legal Disclaimer</h1>
                                        <h5 className="subtitle font-light text">
                                        <strong>Effective Date:</strong> 13-October-2024                                        </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className='policies-content'>
                <p>The information provided by Maestros Technical Services Limited on our website is for general informational purposes only. While we strive to keep the information accurate and up to date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, or availability of the information.</p>

                <h4>1. No Professional Advice</h4>
                <p>The content on our site does not constitute professional advice. You should seek professional guidance for specific issues.</p>

                <h4>2. External Links</h4>
                <p>Our website may contain links to third-party websites. We do not endorse or assume any responsibility for the content of these sites. Use them at your own risk.</p>

                <h4>3. Limitation of Liability</h4>
                <p>In no event will we be liable for any loss or damage arising from your reliance on the information provided on our website.</p>

                <h4>4. Changes to Disclaimer</h4>
                <p>We may update this disclaimer from time to time. Please check this page periodically for updates.</p>

                 </div>
                <Footer />
            </div>
        </div>
    );
}

export default LegalDisclaimer;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import AntivirusSolutions from '../../../assets/images/services/PC-Maintenance/Antivirus-Solutions.jpg';
import SoftwareInstallations from '../../../assets/images/services/PC-Maintenance/Software-Installations.png';
import SystemOptimization from '../../../assets/images/services/PC-Maintenance/System-Optimization.png';
import ComprehensivePCMaintenance from '../../../assets/images/services/PC-Maintenance/Comprehensive-PC-Maintenance.png';
import ExpertPCMaintenance from '../../../assets/images/services/PC-Maintenance/Expert-PC-Maintenance.png';

const PCMaintenance = () => {
    const handleTrialClick = () => {
        // Define your free trial link or logic here
        window.location.href = "/free-trial"; // Example redirect
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>PC Maintenance Services | Maestros Technical Services</title>
                <meta name="description" content="Keep your systems running smoothly with our PC maintenance services. Reliable support for individuals and businesses in the UK. Antivirus solutions, software installations, and system optimization." />
                <meta name="keywords" content="PC maintenance, IT support, antivirus installation, system optimization, software installation, UK business IT solutions, PC repair" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="PC Maintenance Services | Maestros Technical Services" />
                <meta property="og:description" content="Ensure your computer operates at peak performance with our expert PC maintenance services. We offer antivirus solutions, software installations, and system optimization for businesses and individuals." />
                <meta property="og:image" content={ExpertPCMaintenance} />
                <meta property="og:url" content="https://www.mtechsecurity.com/pc-maintenance" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="PC Maintenance Services | Maestros Technical Services" />
                <meta name="twitter:description" content="Optimize and protect your PC with our maintenance services. Antivirus, software setup, and system performance solutions for businesses and individuals in the UK." />
                <meta name="twitter:image" content={ExpertPCMaintenance} />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="pc-maintenance-cover" id="pc-maintenance">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <div>
                                        <h1 className="title text-white font-bold">PC Maintenance Services</h1>
                                        <h5 className="subtitle font-light text-white">
                                            At Maestros, we offer comprehensive PC maintenance services to keep your systems running smoothly. From antivirus solutions to software installations and regular upkeep, our expert team ensures your computer operates at peak performance, safeguarding your data and enhancing your experience.
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Our PC Maintenance Services</h2>
                                        <h6 className="subtitle">Count on us for all your PC maintenance needs.</h6>
                                    </Col>
                                </Row>
                                <Row className="m-t-40">
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={AntivirusSolutions} alt="Antivirus Solutions for PC Protection" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Antivirus Solutions</h5>
                                                <p className="m-b-0 font-14">
                                                    Protect your PC with our reliable antivirus solutions. We offer installation and configuration of top-tier antivirus software, ensuring your system is safeguarded against malware, viruses, and other online threats. Regular updates and scans keep your data secure.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={SoftwareInstallations} alt="Professional Software Installations for Optimal PC Performance" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Software Installations</h5>
                                                <p className="m-b-0 font-14">
                                                    Our team specialises in installing essential software to enhance your PC’s performance. Whether it’s operating systems, productivity suites, or specialised applications, we ensure your software is correctly installed and configured for optimal use.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={SystemOptimization} alt="System Optimization Services for Enhanced PC Efficiency" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">System Optimisation</h5>
                                                <p className="m-b-0 font-14">
                                                    Regular maintenance is crucial for your PC’s longevity. Our system optimisation services improve speed and performance by removing unnecessary files and ensuring your computer runs efficiently. Experience faster boot times and smoother operation.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="bg-light">
                            <section>
                                <div id="banner1" className="banner spacer">
                                    <Container>
                                        <Row>
                                            <Col lg="5" md="7" className="align-self-center">
                                                <h2 className="title font-bold">Comprehensive PC Maintenance at Maestros</h2>
                                                <p className="m-t-40 m-b-30">
                                                    Our PC maintenance services are designed to ensure your computer remains in optimal condition. We provide a full range of services, from antivirus installations to software upgrades and system optimisation. With our dedicated team, you can enjoy a seamless computing experience while keeping your data secure.
                                                </p>
                                            </Col>
                                            <Col lg="6" md="5" className="align-self-center ms-auto">
                                                <img src={ComprehensivePCMaintenance} alt="Comprehensive PC Maintenance Solutions" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </section>
                        </div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Expert PC Maintenance for Optimal Performance</h2>
                                        <h6 className="subtitle">At Maestros, your satisfaction is our priority.</h6>
                                        <p>
                                            Our PC maintenance services are tailored to meet your needs. With a focus on security and performance, our team ensures that your systems run smoothly, providing you with peace of mind and a hassle-free computing experience.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="spacer bg-light">
                            <Container className="feature30">
                                <Row>
                                    <Col lg="10">
                                        <img src={ExpertPCMaintenance} className="rounded img-responsive" alt="Expert PC Maintenance Solutions" />
                                    </Col>
                                    <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                        <Card className="card-shadow">
                                            <CardBody>
                                                <div className="p-20">
                                                    <span className="label label-info label-rounded">Your Maintenance Partner</span>
                                                    <h3 className="title">Expert PC Maintenance Solutions</h3>
                                                    <p>
                                                        At Maestros, we provide tailored PC maintenance services to ensure your computer remains in peak condition. Our experienced team is committed to delivering reliable solutions, keeping your systems safe and efficient.
                                                    </p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Need reliable maintenance? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our PC Maintenance Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we provide comprehensive PC maintenance services, including antivirus installation, software setup, and system optimisation.
                                        <br />
                                        Keep your systems running smoothly and securely. Ready to enhance your PC performance? Sign up for a free trial of our maintenance services today!
                                    </h6>
                                    <Button color="primary" className="mt-3" onClick={handleTrialClick}>
                                        Start Your Free Trial
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default PCMaintenance;

/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

import AccidentalDeletionRecovery from '../../../assets/images/services/Data-Recovery/AccidentalDeletionRecovery.png';
import HardwareFailureRecovery from '../../../assets/images/services/Data-Recovery/HardwareFailureRecovery.png';
import FileCorruptionRecovery from '../../../assets/images/services/Data-Recovery/FileCorruptionRecovery.png';
import DataRecoveryConcept from '../../../assets/images/services/Data-Recovery/data-recovery-concept-vector.png';

import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WinClean_pc_cleaner from '../../../assets/images/products/Winclean-removebg-1950x1950.png';

const Productcomponent = () => {
    return (
        <div>
            <div className="spacer bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Our Products</h1>
                            <h6 className="subtitle">Explore our innovative products designed to elevate your experience. Create your dream setup effortlessly with our extensive range.</h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        <Col md="5" className="d-flex justify-content-center align-items-center">
                            <div className="text-center bg-light">
                                <img src={WinClean_pc_cleaner} alt="EHSP Home Security Product" className="product-img img-rounded" style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                        </Col>
                        <Col md="6"> {/* Added mt-4 for top margin */}
                        <p></p>
                            <h2>WinClean PC Cleaner</h2>
                            <p>
                                ** Your complete solution for system optimization, ensuring speed, security, and reliability.
                            </p>        
                            <div className="features-list">
                                <ul className="list-checkmark list-skin-green">
                                    {[
                                        { text: 'Boost internal speed', description: 'Optimize system performance for a faster experience.' },
                                        { text: 'Remove malware and viruses', description: 'Keep your system safe from harmful threats.' },
                                        { text: 'Full system scan', description: 'Comprehensive checks for optimal system health.' },
                                        { text: 'Full diagnostics', description: 'Identify issues and improve performance with detailed diagnostics.' },
                                        { text: 'Repair Windows corrupt registry', description: 'Restore your system\'s integrity and stability.' },
                                        { text: 'Improve keyboard and mouse speed', description: 'Enhance your productivity with responsive controls.' },
                                        { text: 'Junk removal', description: 'Keep your system clean and clutter-free.' },
                                        { text: 'Temporary file removal', description: 'Clear out unnecessary files for better performance.' },
                                    ].map((item, index) => (
                                        <li key={index}>
                                            <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                                            &nbsp;{item.text}
                                            <div className="feature-description">
                                                {item.description}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <hr></hr>
                            <div>
                            <a className="btn bg-success-gradiant btn-arrow btn-block" href="https://winclean.mtechsecurity.com/">Free Download</a>
                            </div>
                        </Col>
                    </Row>
                    {/* <hr></hr>
                    <Row>
                    <Col md="3">
                        <Card className="card-shadow">
                            <a href="#" className="img-ho">
                                <img className="card-img-top" src={AccidentalDeletionRecovery} alt="Accidental Deletion Recovery Service" />
                            </a>
                            <CardBody>
                                <h5 className="font-medium m-b-0">Accidental Deletion Recovery</h5>
                               
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-shadow">
                            <a href="#" className="img-ho">
                                <img className="card-img-top" src={HardwareFailureRecovery} alt="Hardware Failure Data Recovery Service" />
                            </a>
                            <CardBody>
                                <h5 className="font-medium m-b-0">Hardware Failure Recovery</h5>
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-shadow">
                            <a href="#" className="img-ho">
                                <img className="card-img-top" src={FileCorruptionRecovery} alt="File Corruption Recovery Service" />
                            </a>
                            <CardBody>
                                <h5 className="font-medium m-b-0">File Corruption Recovery</h5>
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="3">
                        <Card className="card-shadow">
                            <a href="#" className="img-ho">
                                <img className="card-img-top" src={FileCorruptionRecovery} alt="File Corruption Recovery Service" />
                            </a>
                            <CardBody>
                                <h5 className="font-medium m-b-0">File Corruption Recovery</h5>
                                
                            </CardBody>
                        </Card>
                    </Col>
                    </Row> */}
                </Container>
            </div>
        </div>
        );
    }
export default Productcomponent;

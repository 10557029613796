import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";
import ContactComponentForServices from "../sections/contactcomponentforservices.jsx";

const Servicerequest = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Service Request | Maestros Technical Services</title>
                <meta name="description" content="Request IT services easily with Maestros. We’re here to help with your technology needs, from network security to tech support." />
                <meta name="keywords" content="service request, IT services, technology help, security services, network setup, UK, IT support" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Service Request | Maestros Technical Services" />
                <meta property="og:description" content="Get tailored IT solutions with Maestros. Request services for network security, antivirus support, and more, designed to optimize your systems and keep them secure." />
                <meta property="og:image" content="https://www.mtechsecurity.com/assets/images/services/maestros-service-request.jpg" />
                <meta property="og:url" content="https://www.mtechsecurity.com/service-request" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Service Request | Maestros Technical Services" />
                <meta name="twitter:description" content="Request IT services easily. From network security to tech support, we provide solutions designed to optimize and secure your technology." />
                <meta name="twitter:image" content="https://www.mtechsecurity.com/assets/images/services/maestros-service-request.jpg" />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="service-request" id="coming">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h1 className="title text-white font-bold">Help Us Build the Future!</h1>
                                            <h5 className="subtitle font-light text-white">
                                                At Maestros, we are committed to developing customized applications tailored to your specific needs.
                                                <br />
                                                Whether it's enhancing data recovery solutions, implementing remote support systems, or optimizing PC maintenance services, we are ready to bring your vision to life.
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <ContactComponentForServices />

                    <div className="ask-masteros-services" id="ask-masteros-services">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h2 className="title text font-weight-bold">Our Commitment to You</h2>
                                            <p>
                                                At Maestros, we prioritize your needs. Our team is dedicated to providing top-notch services, ensuring your systems are secure and efficient. With a focus on quality and customer satisfaction, we strive to exceed your expectations with every solution we deliver.
                                            </p>
                                            <p className="mt-3">
                                                Our services include Security Service, Network Security Setup, Network Security Antivirus, Antivirus Software Support, Antivirus Software Installation, and Tech Support Services—all designed to protect your systems and enhance your efficiency.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Servicerequest;

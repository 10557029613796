/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavLink, NavbarToggler, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import logo from '../../assets/images/logos/white-text.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="topbar" id="top">
            <div className="header6">
                <Container className="po-relative">
                    <Navbar className="navbar-expand-lg h6-nav-bar">
                        <NavbarBrand href="/">
                            <img src={logo} alt="Maestros Technical Services Limited" />
                        </NavbarBrand>

                        <NavbarToggler onClick={toggle}>
                            <span className="ti-menu"></span>
                        </NavbarToggler>

                        <Collapse isOpen={isOpen} navbar className="hover-dropdown font-14 justify-content-end" id="h5-info">
                            <Nav navbar className="ms-auto">
                                <NavItem className="active">
                                    <Link className="nav-link" to="/">Home</Link>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        Our Services <i className="fa fa-angle-down m-l-1"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="b-none animated fadeInUp">
                                        {[
                                            { path: "/AntivirusServices", label: "Antivirus Software Support" },
                                            { path: "/DataRecovery", label: "Data Recovery" },
                                            { path: "/NetworkSecuritySetup", label: "Network Security Setup" },
                                            { path: "/RemoteSupport", label: "Remote Support" },
                                            { path: "/PcMaintenance", label: "System Maintenance" },
                                            { path: "/VirusMalwareRemoval", label: "Virus and Malware Removal" },
                                        ].map(service => (
                                            <DropdownItem key={service.path}>
                                                <Link className="dropdown-item" to={service.path}>{service.label}</Link>
                                            </DropdownItem>
                                        ))}
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            <Link className="dropdown-item" to="/Servicerequest">Ask Maestros</Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavItem>
                                    <Link className="nav-link" to="/downloads">Our Products</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/about">About Us</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </NavItem>
                            </Nav>
                            <div className="act-buttons">
                                <Link 
                                    to="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="btn btn-success-gradiant font-14"
                                >
                                    Get Expert Advice
                                </Link>
                            </div>
                        </Collapse>
                    </Navbar>
                </Container>
            </div>
        </div>
    );
}

export default Header;

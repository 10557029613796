import React, { useState } from 'react'; // Import useState
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";
import { faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WinClean_pc_cleaner from '../../../assets/images/products/Winclean-removebg-1950x1950.png';

const Downloads = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Download Page | Maestros Technical Services</title>
                <meta name="description" content="Access a range of resources and tools from Maestros Technical Services to enhance your IT experience." />
                <meta name="keywords" content="downloads, IT resources, technology tools, UK, PC Cleaner, software tools" />
                <meta property="og:title" content="Download Page | Maestros Technical Services" />
                <meta property="og:description" content="Access a range of resources and tools from Maestros Technical Services to enhance your IT experience." />
                <meta property="og:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
                <meta property="og:url" content="https://mtechsecurity.com/downloads" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Download Page | Maestros Technical Services" />
                <meta name="twitter:description" content="Access a range of resources and tools from Maestros Technical Services to enhance your IT experience." />
                <meta name="twitter:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="download-section" id="resources">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h1 className="title text-white font-bold">Unlock Your Potential!</h1>
                                            <h5 className="subtitle font-light text-white">
                                                At Maestros, we provide a variety of resources designed to empower your technology journey.
                                                <br />
                                                From software tools to comprehensive guides, explore our offerings and enhance your IT capabilities.
                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Free Downloadable Products</h1>
                                    <h6 className="subtitle">
                                        Explore our innovative products designed to elevate your experience. Create your dream setup effortlessly with our extensive range.
                                    </h6>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="m-t-40">
                                <Col md="5" className="d-flex justify-content-center align-items-center">
                                    <div className="text-center bg-light">
                                        <img 
                                            src={WinClean_pc_cleaner} 
                                            alt="WinClean PC Cleaner - System Optimization Tool" 
                                            className="product-img img-rounded" 
                                            style={{ maxWidth: '100%', height: 'auto' }} 
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <p></p>
                                    <h2>WinClean PC Cleaner</h2>
                                    <p><strong>Your complete solution for system optimization, ensuring speed, security, and reliability.</strong></p>        
                                    <div className="features-list">
                                        <ul className="list-checkmark list-skin-green">
                                            {[
                                                { text: 'Boost internal speed', description: 'Optimize system performance for a faster experience.' },
                                                { text: 'Remove malware and viruses', description: 'Keep your system safe from harmful threats.' },
                                                { text: 'Full system scan', description: 'Comprehensive checks for optimal system health.' },
                                                { text: 'Full diagnostics', description: 'Identify issues and improve performance with detailed diagnostics.' },
                                                { text: 'Repair Windows corrupt registry', description: 'Restore your system\'s integrity and stability.' },
                                                { text: 'Improve keyboard and mouse speed', description: 'Enhance your productivity with responsive controls.' },
                                                { text: 'Junk removal', description: 'Keep your system clean and clutter-free.' },
                                                { text: 'Temporary file removal', description: 'Clear out unnecessary files for better performance.' },
                                            ].map((item, index) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="check-icon" />
                                                    &nbsp;{item.text}
                                                    <div className="feature-description">
                                                        {item.description}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <hr />
                                    <div>
                                        <a 
                                            className="btn bg-success-gradiant btn-arrow btn-block" 
                                            href="https://winclean.mtechsecurity.com/"
                                        >
                                            Free Download
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <Servicepricing /> */}
                    <div className="mini-spacer bg-success text-white c2a7">
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Can’t find what you’re looking for? Reach out to us at +44 73658 811 64.</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="discription-download-section" id="discription-download-section">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h2 className="title font-weight-bold">Your Trusted Partner in Security</h2>
                                            <p>
                                                At Maestros, we are committed to putting you first. Our expert team is focused on delivering exceptional services that ensure your systems are not only secure but also optimized for peak performance. We aim to surpass your expectations with each solution we provide.
                                            </p>
                                            <p className="mt-3">
                                                Our comprehensive services include Security Consulting, Network Security Configuration, Antivirus Solutions, Ongoing Antivirus Software Support, Software Installation, and Technical Assistance. Each service is crafted to safeguard your systems and boost your operational efficiency.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Downloads;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Row,
    Col,
    Container,
} from "reactstrap";

// core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

const TermsOfUse = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Terms of Use | Maestros Technical Services</title>
                <meta name="description" content="Read the terms of use for Maestros Technical Services. Understand our policies regarding the use of our services." />
                <meta name="keywords" content="terms of use, legal information, policies, UK" />
            </Helmet>
            <div className="page-wrapper">
            <div className="container-fluid">
                    <div className="policies-cover" id="policies">
                        <Container className="py-5 mt-5">
                            <Row>
                            <Col md="12">
                                    <div>
                                        <div>
                                        <h1 className="title text font-bold">Term of use</h1>
                                        <h5 className="subtitle font-light text">
                                        <strong>Effective Date:</strong> 13-October-2024                                        </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className='policies-content'>
                <p>Welcome to Maestros Technical Services Limited. By accessing or using our website and services, you agree to be bound by the following terms and conditions.</p>
                <h4>1. Acceptance of Terms</h4>
                <p>By using our services, you confirm that you are of legal age and have the capacity to enter into these terms.</p>
                <h4>2. Use of Services</h4>
                <p>You agree to use our services only for lawful purposes and in accordance with these Terms.</p>
                <h4>3. User Accounts</h4>
                <p>You may need to create an account to access certain services. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.</p>
                <h4>4. Intellectual Property</h4>
                <p>All content on our website, including text, graphics, logos, and images, is the property of Maestros Technical Services Limited and is protected by copyright and trademark laws. You may not use, reproduce, or distribute any content without our express written consent.</p>
                <h4>5. Limitation of Liability</h4>
                <p>Our liability for any claim arising from your use of our services is limited to the maximum extent permitted by law. We shall not be liable for any indirect, incidental, special, consequential, or punitive damages.</p>
                <h4>6. Changes to Terms</h4>
                <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website. Your continued use of the services after any changes constitutes acceptance of the new Terms.</p>
                {/* <h4>7. Governing Law</h4>
                <p>These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts located in [Insert Location].</p>
                 */}
                 </div>
                <Footer />
            </div>
        </div>
    );
}

export default TermsOfUse;

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet"; // Import react-helmet

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import BannerComponent from "./sections/bannercomponent.jsx";
import FeatureComponent from "./sections/featurecomponent.jsx";
import PartnerComponent from "./sections/partners.jsx";
import PortfolioComponent from "./sections/portfoliocomponent.jsx";
import Productcomponent from "./sections/productcomponent.jsx";
import ContactComponent from "./sections/contactcomponent.jsx";
import FormBannerComponent from "./sections/formbannercomponent.jsx";

const Components = () => {
    return (
        <div id="main-wrapper">
            {/* Adding SEO Meta Tags */}
            <Helmet>
                <title>Maestros Security - Antivirus & Cybersecurity Solutions</title>
                <meta name="description" content="Maestros Security offers expert Antivirus Software, PC Cleaner & Optimization, and Internet Booster services to enhance your system’s security and performance for UK businesses and clients worldwide." />
                <meta name="robots" content="index, follow" /> {/* Ensure Googlebot follows this page */}
                <meta property="og:title" content="Maestros Security - Cybersecurity Solutions" />
                <meta property="og:description" content="Protect your business with Maestros Security's expert antivirus services, system optimization, and internet speed boosting." />
                <meta property="og:image" content="https://www.mtechsecurity.com/logo.png" />
                <meta property="og:url" content="https://www.mtechsecurity.com" />
                <meta name="twitter:card" content="summary_large_image" />

                {/* Structured Data for better search visibility */}
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Maestros Security",
                        "url": "https://www.mtechsecurity.com",
                        "logo": "https://www.mtechsecurity.com/logo.png",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+1-800-555-5555",
                            "contactType": "Customer Service",
                            "areaServed": "US",
                            "availableLanguage": "English"
                        }
                    }
                    `}
                </script>
            </Helmet>

            <Header />
            <div className="page-wrapper">
                <div className="container-fluid">
                    {/* <HeaderComponent /> */}
                    {/* <HeaderBanner /> */}
                    <BannerComponent />
                    <FeatureComponent />
                    <PartnerComponent />
                    <PortfolioComponent />
                    <Productcomponent />
                    {/* <BlogComponent /> */}
                    {/* <PricingComponent /> */}
                    {/* <TestimonialComponent /> */}
                    {/* <C2aComponent /> */}
                    <ContactComponent />
                    <FormBannerComponent />
                    {/* <CallToAction /> */}
                </div>
            </div>
            <Footer />
        </div>
    );
}

Components.propTypes = {
    classes: PropTypes.object
};

export default Components;

/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import NetworkSecuritySetup from '../../../assets/images/services/All/Network-Security-Setup.png';
import AntivirusServices from '../../../assets/images/services/All/Antivirous-services.png';
import ComprehensivePCMaintenance from '../../../assets/images/services/All/Comprehensive-PC-Maintenance.png';
import TechSupport from '../../../assets/images/services/All/Tech-Support.jpg';
import MalwareRemoval from '../../../assets/images/services/All/Malware-removal.png';
import DataRecovery from '../../../assets/images/services/All/AccidentalDeletionRecovery.png';

const PortfolioComponent = () => {
    return (
        <div>
            <div className="spacer">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h2 className="title">Our Comprehensive IT Security Services in the UK</h2>
                            <h6 className="subtitle">
                                Rely on our extensive IT solutions, including network security setup, antivirus software installation, and ongoing tech support. We ensure your systems are secure and efficient against threats.
                            </h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        {[
                            {
                                title: 'Network Security Setup',
                                imgSrc: NetworkSecuritySetup,
                                altText: 'Network Security Setup Services in the UK',
                                description: 'Enhance your cybersecurity with our network security setup services. Our experts configure firewalls, VPNs, and intrusion detection systems to protect your network from potential threats.',
                                link: '/NetworkSecuritySetup',
                            },
                            {
                                title: 'Antivirus Software Installation',
                                imgSrc: AntivirusServices,
                                altText: 'Antivirus Software Installation Services in the UK',
                                description: 'Ensure your systems are secure with our professional antivirus software installation services. We help you choose and install the best antivirus solutions for optimal protection.',
                                link: '/AntivirusServices',
                            },
                            {
                                title: 'PC Maintenance',
                                imgSrc: ComprehensivePCMaintenance,
                                altText: 'Comprehensive PC Maintenance Services in the UK',
                                description: 'Regular PC maintenance is essential for optimal performance. Our services include system updates, hardware checks, and software optimization to keep your devices running smoothly.',
                                link: '/PcMaintenance',
                            },
                            {
                                title: 'Tech Support Services',
                                imgSrc: TechSupport,
                                altText: 'Tech Support Services in the UK',
                                description: 'Our tech support services provide expert assistance for all your IT needs, including troubleshooting and support for antivirus software and other issues.',
                                link: '/RemoteSupport',
                            },
                            {
                                title: 'Virus and Malware Removal',
                                imgSrc: MalwareRemoval,
                                altText: 'Virus and Malware Removal Services in the UK',
                                description: 'Protect your data with our virus and malware removal services. Our team uses advanced techniques to ensure your systems are clean and secure from all threats.',
                                link: '/VirusMalwareRemoval',
                            },
                            {
                                title: 'Data Recovery Services',
                                imgSrc: DataRecovery,
                                altText: 'Data Recovery Services in the UK',
                                description: 'Our data recovery services are essential for retrieving lost data due to hardware failure or accidental deletion. We help you recover critical information effectively and securely.',
                                link: '/DataRecovery',
                            },
                        ].map((service, index) => (
                            <Col md="4" key={index}>
                                <Card className="card-shadow">
                                    <Link to={service.link} className="img-ho">
                                        <img className="card-img-top" src={service.imgSrc} alt={service.altText} />
                                    </Link>
                                    <CardBody>
                                        <h5 className="font-medium m-b-0">{service.title}</h5>
                                        <p className="m-b-0 font-14">{service.description}</p>
                                        <Link to={service.link} className="linking text-themecolor">
                                            Learn More <i className="ti-arrow-right"></i>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default PortfolioComponent;

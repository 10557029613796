import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";

import AccidentalDeletionRecovery from '../../../assets/images/services/Data-Recovery/AccidentalDeletionRecovery.png';
import HardwareFailureRecovery from '../../../assets/images/services/Data-Recovery/HardwareFailureRecovery.png';
import FileCorruptionRecovery from '../../../assets/images/services/Data-Recovery/FileCorruptionRecovery.png';
import DataRecoveryConcept from '../../../assets/images/services/Data-Recovery/data-recovery-concept-vector.png';
import Dedicated from '../../../assets/images/services/Data-Recovery/dedicated.png';

const DataRecovery = () => {
    const handleConsultationClick = () => {
        // Define your consultation link or logic here
        window.location.href = "/consultation"; // Example redirect
    };

    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Data Recovery Services | Maestros Technical Services</title>
                <meta name="description" content="Professional data recovery services to retrieve lost information. Trust Maestros for effective data recovery solutions in the UK." />
                <meta name="keywords" content="data recovery, IT support, lost data recovery, recovery services, UK" />
                <meta property="og:title" content="Data Recovery Services | Maestros Technical Services" />
                <meta property="og:description" content="Professional data recovery services to retrieve lost information. Trust Maestros for effective data recovery solutions in the UK." />
                <meta property="og:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
                <meta property="og:url" content="https://mtechsecurity.com/" />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Data Recovery Services | Maestros Technical Services" />
                <meta name="twitter:description" content="Professional data recovery services to retrieve lost information. Trust Maestros for effective data recovery solutions in the UK." />
                <meta name="twitter:image" content="https://winclean.mtechsecurity.com/image/logo.png" />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="datarecovery-cover" id="datarecovery">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="8">
                                    <div>
                                        <h1 className="title text-white font-bold">Professional Data Recovery Services</h1>
                                        <h5 className="subtitle font-light text-white">
                                            At Maestros, we specialise in reliable and effective data recovery solutions for individuals and businesses. Whether you're facing accidental deletion, hardware failure, or file corruption, our expert team utilises advanced techniques to restore your valuable information quickly and securely. Trust us to recover your data efficiently so you can focus on what matters most—your work and peace of mind.
                                        </h5>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Our Comprehensive Data Recovery Services</h2>
                                        <h6 className="subtitle">Rely on our expertise for efficient and effective data recovery solutions.</h6>
                                    </Col>
                                </Row>
                                <Row className="m-t-40">
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={AccidentalDeletionRecovery} alt="Accidental Deletion Recovery Service" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Accidental Deletion Recovery</h5>
                                                <p className="m-b-0 font-14">
                                                    Our team specialises in recovering data lost due to accidental deletion. Using advanced recovery techniques, we retrieve files from various storage devices, ensuring that your important information is restored quickly, allowing you to continue your work without significant interruption.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={HardwareFailureRecovery} alt="Hardware Failure Data Recovery Service" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">Hardware Failure Recovery</h5>
                                                <p className="m-b-0 font-14">
                                                    In cases of hardware failure, our experts are equipped to recover lost data from damaged drives. We use specialised tools and techniques to access your data while minimising the risk of further damage, ensuring your vital information is salvaged and protected.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="card-shadow">
                                            <a href="#" className="img-ho">
                                                <img className="card-img-top" src={FileCorruptionRecovery} alt="File Corruption Recovery Service" />
                                            </a>
                                            <CardBody>
                                                <h5 className="font-medium m-b-0">File Corruption Recovery</h5>
                                                <p className="m-b-0 font-14">
                                                    We specialise in recovering data from corrupted files due to software issues or malware. Our team employs advanced techniques to repair and retrieve your important documents swiftly, ensuring that your data integrity is maintained.
                                                </p>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="bg-light">
                            <section>
                                <div id="banner1" className="banner spacer">
                                    <Container>
                                        <Row>
                                            <Col lg="5" md="7" className="align-self-center">
                                                <h2 className="title font-bold">Data Recovery Solutions You Can Trust</h2>
                                                <p className="m-t-40 m-b-30">
                                                    Data recovery is critical for retrieving lost or inaccessible information. At Maestros, we understand that data loss can be devastating. Our team conducts thorough assessments and utilises state-of-the-art tools for a systematic recovery process tailored to your needs. With our expertise in data recovery, you can rest assured that your information is in capable hands.
                                                </p>
                                            </Col>
                                            <Col lg="6" md="5" className="align-self-center ms-auto">
                                                <img src={DataRecoveryConcept} alt="Data Recovery Process Illustration" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </section>
                        </div>
                        <div className="spacer">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="7" className="text-center">
                                        <h2 className="title">Revive Your Data: Expert Recovery Solutions</h2>
                                        <h6 className="subtitle">At Maestros, we excel in recovering lost or inaccessible data.</h6>
                                        <p>
                                            Our skilled team is dedicated to using advanced tools for quick and efficient data recovery. Whether due to accidental deletion, hardware failure, or malware attacks, you can trust us to restore your valuable information securely and swiftly, ensuring minimal disruption to your life or business.
                                        </p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="spacer bg-light">
                            <Container className="feature30">
                                <Row>
                                    <Col lg="5">
                                        <img src={Dedicated} className="rounded img-responsive" alt="Dedicated Data Recovery Services" />
                                    </Col>
                                    <Col lg="5" md="7" className="text-center wrap-feature30-box">
                                        <Card className="card-shadow">
                                            <CardBody>
                                                <div className="p-20">
                                                    <span className="label label-info label-rounded">Revive Your Data</span>
                                                    <h3 className="title">Expert Recovery Solutions</h3>
                                                    <p>
                                                        At Maestros, we provide expert data recovery services to retrieve lost or damaged information. Our experienced team uses cutting-edge tools to handle various issues, including accidental deletions, hardware failures, and malware attacks. You can count on us to bring your data back to life with the utmost care and precision.
                                                    </p>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="mini-spacer bg-success text-white c2a7">
                        <Container>
                            <div className="d-flex justify-content-between">
                                <div className="display-7 align-self-center">Are you satisfied with our services? Connect with us on +44 73658 811 64</div>
                                <div className="ms-auto m-t-10 m-b-10">
                                    <a 
                                        className="btn btn-outline-light text-white btn-md" 
                                        href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                    >
                                        Call Us
                                    </a>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className="spacer bg-light">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="7" className="text-center">
                                    <h1 className="title font-bold">Explore Our Data Recovery Solutions</h1>
                                    <h6 className="subtitle">
                                        At Maestros, we specialise in comprehensive data recovery services, including accidental deletion recovery, hardware failure recovery, and file corruption repair. 
                                        <br />
                                        Experience peace of mind knowing your valuable data is in expert hands. Ready to recover what matters most? Sign up for a free consultation today!
                                    </h6>
                                    <Button color="btn btn-success-gradiant" className="mt-3" onClick={handleConsultationClick}>
                                        Start Your Free Consultation
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default DataRecovery;

/* eslint-disable */
import React from 'react';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import secureworld from '../../../assets/images/features/feature30/Secure-World-4.gif';

// SEO Elements
export const seo = {
    title: "Comprehensive IT Solutions in the UK | Maestros Technical Services",
    description: "Explore our wide range of IT solutions including virus removal, remote support, data recovery, network security setup, and antivirus software installation tailored for UK businesses."
};

const FeatureComponent = () => {
    return (
        <div>
            <div className="spacer feature4">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <span className="label label-danger label-rounded">Comprehensive IT Solutions</span>
                            <h1 className="title font-bold">Our Comprehensive IT Solutions in the UK</h1>
                            <h6 className="subtitle">
                                We offer a wide range of comprehensive IT solutions, including network security setup, antivirus software installation, PC maintenance, virus and malware removal, remote support, and data recovery services. Our commitment to exceptional service ensures your technology needs are met with precision and expertise.
                            </h6>
                        </Col>
                    </Row>
                    <Row className="m-t-40">
                        {[{
                            title: 'Virus and Malware Removal',
                            icon: 'fa-shield-alt',
                            description: 'Our expert team ensures the complete removal of viruses and malware, safeguarding your data and systems from potential threats. We utilize advanced techniques for thorough cleaning and protection for your devices.',
                            link: '/VirusMalwareRemoval',
                        },
                        {
                            title: 'Remote Support',
                            icon: 'fa-life-ring',
                            description: 'Our remote support services allow us to troubleshoot and resolve your IT issues quickly and effectively, ensuring you have continuous access to expert tech support whenever needed.',
                            link: '/RemoteSupport',
                        },
                        {
                            title: 'Data Recovery',
                            icon: 'fa-database',
                            description: 'Our data recovery services help you retrieve critical information in case of data loss. Whether due to hardware failure or accidental deletion, we employ proven methods to recover your valuable data efficiently.',
                            link: '/DataRecovery',
                        },
                        {
                            title: 'PC Maintenance',
                            icon: 'fa-tools',
                            description: 'Regular PC maintenance ensures optimal performance and security of your IT systems. We offer tailored maintenance plans that include updates, backups, and monitoring to keep your systems running smoothly.',
                            link: '/PcMaintenance',
                        },
                        {
                            title: 'Network Security Setup',
                            icon: 'fa-lock',
                            description: 'Protecting your business from cyber threats is essential. Our network security setup services configure robust security solutions, including firewalls and intrusion detection systems, ensuring your network remains secure.',
                            link: '/NetworkSecuritySetup',
                        },
                        {
                            title: 'Antivirus Software Installation',
                            icon: 'fa-virus',
                            description: 'Our team provides expert installation of antivirus software, ensuring your systems are well-protected against malware and cyber threats. We also offer ongoing support to keep your security measures up to date.',
                            link: '/AntivirusServices',
                        }].map((feature, index) => (
                            <Col md="6" className="wrap-feature4-box" key={index}>
                                <Card>
                                    <CardBody>
                                        <div className="icon-round bg-light-info">
                                            <i className={`fa ${feature.icon}`}></i>
                                        </div>
                                        <h5 className="font-medium">{feature.title}</h5>
                                        <p className="m-t-20">{feature.description}</p>
                                        <Link to={feature.link} className="linking text-themecolor">
                                            Explore More <i className="ti-arrow-right"></i>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
            <div className="mini-spacer bg-success text-white c2a7">
                <Container>
                    <div className="d-flex justify-content-between">
                        <div className="display-7 align-self-center">Reach out to us at +44 73658 811 64.</div>
                        <div className="ms-auto m-t-10 m-b-10">
                            <a 
                                className="btn btn-outline-light text-white btn-md" 
                                href="https://api.whatsapp.com/send/?phone=447365881164&text&type=phone_number&app_absent=0" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Call Us
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="spacer">
                <Container className="feature30">
                    <Row>
                        <Col lg="8">
                            <img src={secureworld} className="rounded img-responsive" alt="Secure IT Solutions for Businesses in the UK" />
                        </Col>
                        <Col lg="5" md="7" className="text-center wrap-feature30-box">
                            <Card className="card-shadow">
                                <CardBody>
                                    <div className="p-20">
                                        <span className="label label-info label-rounded">Secure IT Solutions</span>
                                        <h3 className="title">Delivering Comprehensive Protection for Your Digital Environment</h3>
                                        <p>
                                            In today's fast-paced digital landscape, ensuring the security of your information is paramount. Our Secure IT Solutions provide a robust framework for protecting your data, networks, and systems against an array of cyber threats. With services that include antivirus protection, network security setup, and continuous security assessments, we empower you to focus on your core business activities while we safeguard your digital assets.
                                        </p>
                                        <Link to="/about" className="btn btn-success-gradiant btn-md btn-arrow m-t-20">
                                            <span>Learn More <i className="ti-arrow-right"></i></span>
                                        </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default FeatureComponent;

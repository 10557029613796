import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from 'reactstrap';

// Core components
import Header from "../../../components/header/header.jsx";
import Footer from "../../../components/footer/footer.jsx";
import ContactForm from "../sections/contactcomponent.jsx";

const ContactUs = () => {
    return (
        <div id="main-wrapper">
            <Header />
            <Helmet>
                <title>Contact Us | Maestros Technical Services</title>
                <meta name="description" content="Contact Maestros for expert IT support and security services in the UK. We offer tailored solutions including network security setup, Antivirus Software Support, and more." />
                <meta name="keywords" content="Security service, Network security setup, Network security antivirus, Antivirus Software Support, Antivirus software installation, Tech support services, UK" />
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid">
                    <div className="contact-us" id="contact">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h1 className="title text-white font-bold">We'd Love to Hear From You!</h1>
                                            <h5 className="subtitle font-light text-white">
                                            If you need any solutions regarding security services, network security setup, network security antivirus, Antivirus Software Support, antivirus software installation, or tech support services, please don’t hesitate to reach out!                                            </h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <ContactForm />
                    <div className="our-commitment" id="commitment">
                        <Container className="py-5 mt-5">
                            <Row>
                                <Col md="6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <h2 className="title text font-weight-bold">Your Satisfaction is Our Priority</h2>
                                            <p>
                                                At Maestros, we prioritize your needs. Our dedicated team is here to ensure your queries about tech support services are addressed promptly and effectively. 
                                            </p>
                                            <p className="mt-3">
                                                We provide a range of services including antivirus software installation and comprehensive network security antivirus solutions. Contact us today to learn more about how our tailored IT services can protect your systems and enhance your efficiency in the UK.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default ContactUs;
